import { EnumToArray } from '../../../utils/enumToArray';

enum DrawerType {
  Bag = 'bag',
  BagEmpty = 'bagEmpty',
  SalesMenu = 'salesMenu',
  ServicesMenu = 'servicesMenu',
}

enum AnalyticsTrackingKind {
  HeaderLinkClicked = 'Header Link Clicked',
  ProductAdded = 'Product Added',
  ProductRemoved = 'Product Removed',
  ProductClicked = 'Product Clicked',
  PromotionClicked = 'Promotion Clicked',
  EcosystemLinkClicked = 'Ecosystem Link Clicked',
  PromotionViewed = 'Promotion Viewed',
  SearchRecommendationClicked = 'Search Recommendation Clicked',
  RecommendedProductClicked = 'Recommended Product Clicked',
  RecommendedProductViewed = 'Recommended Product Viewed',
  SearchbarClicked = 'Searchbar Clicked',
  ErrorDisplayed = 'Error Displayed',
}

export const PTZHeaderConfigList = {
  DrawerType: EnumToArray([DrawerType]),
  AnalyticsTrackingKind: EnumToArray([AnalyticsTrackingKind]),
};

export const PTZHeaderConfig = {
  DrawerType,
  AnalyticsTrackingKind
};
